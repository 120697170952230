import React, { useContext } from 'react';
import PageContext from '../../../context/page/pageContext';
import ZupeeVideo from '../zupeeVideo';
import processVideoSectionData from './helper';
import './videoSection.modules.css';

const VideoSection = (props) => {
	const { data, page } = useContext(PageContext);
	const processedData = processVideoSectionData(data, page);
	const { titleText, videoUrl, backgroundColor, videoPoster } = processedData;
	return (
		<div style={{ backgroundColor: backgroundColor }} className='video-section-container' id='video-section-container'>
			<div className='gatsby-custom-container'>
				<div className='gatsby-custom-row'>
					<div className='content'>
						<h2 style={{ color: titleText.color }} className='video-section-title'>
							{titleText.text}
						</h2>
                        {props.zupeePlayer
                        ?
                        ( <ZupeeVideo type={props.type} source={videoUrl} poster={videoPoster} autoPlay={props.autoPlay ? true : false} />)
                        :
						<div className='video-section'>
							<video controls className={'video-player'} poster={videoPoster} preload="none" autoPlay={props.autoPlay ? true : false}>
								<source src={videoUrl} type='video/mp4' />
							</video>
						</div>
                        }
					</div>
				</div>
			</div>
		</div>
	);
};
export default VideoSection;
